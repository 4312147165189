<template>
    <div class="warning-panel-name">
        <div :style="`left: -56px`" class="detail-title">
            <img class="icon-back" @click="backToList" src="/img/project/icon-back.png" alt="" />
            {{ title }}
        </div>
        <div class="warning-search">
            <el-form ref="form" :model="searchForm" size="mini" inline>
                <el-form-item label="">
                    <el-select
                        @change="getWarningList"
                        style="width: 130px"
                        clearable
                        v-model="searchForm.alarmType"
                        placeholder="请选择类型">
                        <el-option
                            v-for="item in warningTaskList"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-select
                        @change="getWarningList"
                        style="width: 130px"
                        clearable
                        v-model="searchForm.statusId"
                        placeholder="请选择处理状态">
                        <el-option
                            v-for="item in statusList"
                            :key="item.id"
                            :label="item.statusName"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        style="width: 280px"
                        :default-time="['00:00:00','23:59:59']"
                        @change="getWarningList"
                        v-model="searchForm.dateRange"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div 
            v-loading="loading"
            element-loading-text="加载中，请稍后"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            class="warning-item-box">
            <div
                class="warning-item"
                v-for="item in warningList"
                :key="item.id"
                :class="selectWarning.id === item.id? 'warning-item-active':''"
                @click="chooseWarning(item)">
                <div class="warning-title">
                    <p>
                        <span>{{item.finalTypeName}}</span>
                        <span class="status confirmed" v-if="item.alarmStatus == 'confirm'">{{$t('warning.confirmed')}}</span>
                        <span class="status corrected" v-else-if="item.alarmStatus == 'amend'">{{$t('warning.corrected')}}</span>
                        <span class="status neglected" v-else-if="item.alarmStatus == 'ignore'">{{$t('warning.neglected')}}</span>
                        <span class="status untreated" v-else>{{$t('warning.untreated')}}</span>
                    </p>
                    <span class="control-icon">
                        <i 
                            @click.stop="moveTo(item)"
                            v-if="item.id===selectWarning.id"
                            style="margin-right: 4px"
                            class="el-icon-aim"></i>
                        <i 
                            @click.stop="needShowWarning(item)"
                            :class="item.show? 'warning-show': ''"
                            class="el-icon-view"></i>
                    </span>
                </div>
                <div class="warning-task">{{item.taskName}} {{item.equipmentName ? '[' + item.equipmentName + ']' : ''}}</div>
                <el-tooltip :content="item.alarmAddress" placement="right">
                    <div class="warning-describe">{{ item.alarmAddress }}</div>
                </el-tooltip>
                <div class="warning-time">{{ item.alarmTime }}</div>
            </div>
        </div>
        <div class="left-table-pagination-line">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page"
                :page-size="size"
                :pager-count="3"
                size="small"
                background
                layout="total, prev, pager, next"
                :total="total"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getDictionary } from "@/api/system/dictbiz";
import { getAIAlarmData } from "@/api/oil-gas/index";

export default {
    name: "AI-identify",
    props: {
        type: {
            type: String,
            default() {
                return null;
            },
        }
    },
    data() {
        return {
            // 搜索表达
            searchForm: {
                statusId: '',
                alarmType: '',
                dateRange: ''
            },
            warningTaskList: [],
            statusList: [
                {id: '', statusName: '所有状态'},
                {id: 'pend', statusName: this.$t('warning.untreated')},
                {id: 'confirm', statusName: this.$t('warning.confirmed')},
                {id: 'amend', statusName: this.$t('warning.corrected')},
                {id: 'ignore', statusName: this.$t('warning.neglected')}
            ],
            size: 5,
            page: 1,
            total: 0,
            loading: true
        }
    },
    computed: {
        ...mapGetters([
            "warningList",
            "selectWarning"
        ]),
        title() {
            return this.$route.query.projectName
        }
    },
    mounted() {
        this.$EventBus.$off('updata-list');
        this.$EventBus.$on('updata-list', () => {
            this.getWarningList();
        });
        this.$nextTick(() => {
            this.$EventBus.$emit('init-isAI', true);
            // 初始化清空一次列表
            this.$store.dispatch("setWarningList", []);
            // 获取报警类型列表
            this.getDictList();
            // 获取报警信息
            this.getWarningList();
        });
    },
    methods: {
        backToList() {
            this.$router.push('/oil-gas/OG-Inspection');
        },
        chooseWarning (node) {
            this.$store.dispatch("setSelectWarning", node);
            this.showDetail()
        },
        needShowWarning (node) {
            this.$store.dispatch("setWarningList", this.warningList.map(item=>{
                if (item.id === node.id) {
                    item.show = !item.show
                }
                return item
            }));
        },
        showDetail (node) {
            this.$EventBus.$emit('warning-event', {
                type: 'detail',
                data: node
            })
        },
        moveTo (node) {
            this.$store.dispatch("setWarningList", this.warningList.map(item=>{
                if (item.id === node.id) {
                    item.show = true
                }
                return item
            }));
            this.showDetail(node)
            setTimeout(()=>{
                this.$EventBus.$emit('warning-event', {
                    type: 'moveTo',
                    data: node
                })
            }, 0)
        },
        getDictList() {
            getDictionary({
                code: 'OilPatrolInspection',
            }).then(res=>{
                res.data.data.unshift({
                    dictValue: '所有类型',
                    dictKey: ''
                })
                this.warningTaskList = res.data.data
            })
        },
        getWarningList() {
            let startTime, endTime;
            if ( Array.isArray(this.searchForm.dateRange) && this.searchForm.dateRange.length === 2) {
                startTime = this.searchForm.dateRange[0]
                endTime = this.searchForm.dateRange[1]
            }
            let projectId = this.$route.query.projectId
            let data = {
                inspectionProjectID: projectId,
                alarmStatus: this.searchForm.statusId,
                current: this.page,
                size: this.size,
                alarmType: this.searchForm.alarmType,
                startTime,
                endTime
            }
            this.loading = true
            getAIAlarmData(data).then(res => {
                let data = res.data.data;
                if (data) {
                    this.total = data.total;
                    this.$store.dispatch("setWarningList", data.records.map(item=>{
                        item.lng = Number(item.lon)
                        item.lat = Number(item.lat)
                        item.show = true
                        item.imgs = item.picLinkUrlList? item.picLinkUrlList: []
                        item.videos = item.videoLinkUrlList? item.videoLinkUrlList: []
                        return item
                    }));
                }
            }).finally(()=>{
                this.loading = false
            })
        },
        handleSizeChange(v) {
            this.size = v
            this.page = 1
            this.getWarningList()
        },
        handleCurrentChange(v) {
            this.page = v
            this.getWarningList()
        }
    }
}
</script>

<style lang="scss">
.warning-panel-name {
    position: relative;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .detail-title {
        position: absolute;
        top: -49px;
        font-size: 14px;
        font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
        font-weight: bold;
        color: #ffffff;
        padding-top: 9px;
        padding-bottom: 11px;
        display: flex;
        align-items: center;
        .icon-back {
            margin-right: 4px;
        }
    }
    .warning-search {
        padding: 5px;
        border-bottom: 1px solid #030303;
    }
    
    .warning-item-box{
        flex-grow: 1;
        overflow: auto;
        .warning-item {
            cursor: pointer;
            height: 126px;
            box-sizing: border-box;
            border-bottom: 1px solid #030303;
            padding: 16px 10px;
            .warning-title {
                font-size: 14px;
                font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 18px;
                display: flex;
                justify-content: space-between;
                .status {
                    font-family: 微软雅黑;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 12px;
                    color: rgb(255, 255, 255);
                    margin-left: 10px;
                    padding: 2px 8px;
                    border-radius: 4px;
                    &.untreated {
                        border: 1px solid #ffffff;
                    }
                    &.confirmed {
                        color: rgb(64, 158, 255);
                        background: #E6F7FF;
                        border: 1px solid rgb(64, 158, 255);
                    }
                    &.corrected {
                        color: rgb(245, 154, 35);
                        background: #E9DFD0;
                        border: 1px solid rgb(245, 154, 35);
                    }
                    &.neglected {
                        color: rgb(242, 242, 242);
                        background: #9A9B9F;
                        border: 1px solid #9A9B9F;
                    }
                }
                .control-icon{
                    color: rgba(153,153,153,0.79);
                }
            }
            .warning-task {
                height: 15px;
                margin-top: 16px;
                font-size: 12px;
                font-family: MicrosoftYaHeiUI;
                color: rgba(153,153,153,0.79);
                line-height: 15px;
            }
            .warning-describe {
                height: 15px;
                overflow: hidden;
                white-space:nowrap;
                text-overflow: ellipsis;
                margin-top: 8px;
                font-size: 12px;
                font-family: MicrosoftYaHeiUI;
                color: rgba(153,153,153,0.79);
                line-height: 15px;
            }
            .warning-time {
                height: 15px;
                margin-top: 8px;
                font-size: 12px;
                font-family: MicrosoftYaHeiUI;
                color: rgba(153,153,153,0.79);
                line-height: 15px;
            }
            .warning-show {
                color: #3573FF;
            }
        }
    }
    
    .warning-item-active {
        background-color: #3573FF33;
    }

    // 局部样式以后会统一 zcj
    .el-select {
      .el-input {
        .el-input__inner {
          background-color: #030303;
          color: #C0C4CC;
        }
        // .el-select__caret {}
      }
    }

    // 局部样式以后会统一 zcj
    .el-date-editor{
      background-color: #030303 !important;
      .el-range-separator {
        color: #C0C4CC;
      }
      .el-range-input {
        background-color: transparent;
        color: #C0C4CC;
      }
      .el-range-input::placeholder {
        color: #303133;
      }
    }
}
</style>